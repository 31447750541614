/**
 * Our scrolling hero state
 */
import React from 'react';
import PropTypes from 'prop-types';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state) => {
    return state;
};

const Provider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, {});

    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.arrayOf(PropTypes.elementType),
    ]),
};

// Export
export const Globals = {
    State,
    Dispatch,
    Provider,
};
